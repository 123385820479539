var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
            _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
              _vm._v("Auditoria"),
            ]),
          ]),
        ],
        1
      ),
      _c("p", [
        _vm._v(
          "Exportar histórico de atividades em um período de até 60 dias."
        ),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "text--primary" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "1" } },
                    [
                      _c(
                        "v-card-title",
                        { staticStyle: { "font-size": "1.2rem" } },
                        [_vm._v("Filtro")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-1",
                      staticStyle: { "max-width": "273px" },
                      attrs: { cols: "12", sm: "3" },
                    },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { model: "dialog", width: "369px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          outlined: "",
                                          label: "Periodo *",
                                          "append-icon": "mdi-calendar",
                                          readonly: "",
                                          dense: "",
                                          color: _vm.filterDateColor,
                                        },
                                        model: {
                                          value: _vm.computeRange,
                                          callback: function ($$v) {
                                            _vm.computeRange = $$v
                                          },
                                          expression: "computeRange",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { range: "" },
                            on: {
                              change: function ($event) {
                                return _vm.fillFilters()
                              },
                            },
                            model: {
                              value: _vm.filter.dates,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "dates", $$v)
                              },
                              expression: "filter.dates",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { attrs: { id: "date-hint-div" } }, [
                        _c("p", { staticClass: "v-application" }, [
                          _vm._v(_vm._s(_vm.pickerMessage)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "min-width": "240px" },
                      attrs: { cols: "12", sm: "2" },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          multiple: "",
                          items: _vm.users,
                          "append-icon": "mdi-chevron-down",
                          "item-text": (user) => `${user.username}`,
                          label: "Nome do usuário",
                          placeholder: "Todos",
                          dense: "",
                          outlined: "",
                          disabled: _vm.disabledInputs,
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.usersSelect.length
                                            } selecionado${
                                              _vm.filter.usersSelect.length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filter.usersSelect,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "usersSelect", $$v)
                          },
                          expression: "filter.usersSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          multiple: "",
                          items: _vm.ips,
                          "append-icon": "mdi-chevron-down",
                          "item-text": (ip) => `${ip}`,
                          label: "IP do usuário",
                          placeholder: "Todos",
                          dense: "",
                          outlined: "",
                          disabled: _vm.disabledInputs,
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.ipsSelect.length
                                            } selecionado${
                                              _vm.filter.ipsSelect.length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filter.ipsSelect,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "ipsSelect", $$v)
                          },
                          expression: "filter.ipsSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          multiple: "",
                          "append-icon": "mdi-chevron-down",
                          items: _vm.services,
                          "item-text": (service) => `${service.description}`,
                          "item-value": "name",
                          label: "Localização no sistema",
                          placeholder: "Todos",
                          dense: "",
                          outlined: "",
                          disabled: _vm.disabledInputs,
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.servicesSelect.length
                                            } selecionado${
                                              _vm.filter.servicesSelect.length >
                                              1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filter.servicesSelect,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "servicesSelect", $$v)
                          },
                          expression: "filter.servicesSelect",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          multiple: "",
                          "append-icon": "mdi-chevron-down",
                          items: _vm.actions,
                          "item-text": (action) => `${action.description}`,
                          "item-value": "name",
                          label: "Atividades",
                          placeholder: "Todos",
                          dense: "",
                          outlined: "",
                          disabled: _vm.disabledInputs,
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.actionsSelect.length
                                            } selecionado${
                                              _vm.filter.actionsSelect.length >
                                              1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filter.actionsSelect,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "actionsSelect", $$v)
                          },
                          expression: "filter.actionsSelect",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _vm._l(_vm.filter.usersSelect, function (user) {
                              return _c(
                                "v-chip",
                                {
                                  key: user.id,
                                  staticClass: "ma-1",
                                  attrs: { close: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onClose(user)
                                    },
                                    "click:close": function ($event) {
                                      return _vm.clickChip(
                                        user,
                                        _vm.filter.usersSelect
                                      )
                                    },
                                  },
                                  model: {
                                    value: user.isOpen,
                                    callback: function ($$v) {
                                      _vm.$set(user, "isOpen", $$v)
                                    },
                                    expression: "user.isOpen",
                                  },
                                },
                                [_vm._v(" " + _vm._s(user) + " ")]
                              )
                            }),
                            _vm._l(_vm.filter.ipsSelect, function (ip) {
                              return _c(
                                "v-chip",
                                {
                                  key: ip,
                                  staticClass: "ma-1",
                                  attrs: { close: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onClose(ip)
                                    },
                                    "click:close": function ($event) {
                                      return _vm.clickChip(
                                        ip,
                                        _vm.filter.ipsSelect
                                      )
                                    },
                                  },
                                  model: {
                                    value: ip.isOpen,
                                    callback: function ($$v) {
                                      _vm.$set(ip, "isOpen", $$v)
                                    },
                                    expression: "ip.isOpen",
                                  },
                                },
                                [_vm._v(" " + _vm._s(ip) + " ")]
                              )
                            }),
                            _vm._l(
                              _vm.filter.servicesSelect,
                              function (service) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: service,
                                    staticClass: "ma-1",
                                    attrs: { close: "" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onClose(service)
                                      },
                                      "click:close": function ($event) {
                                        return _vm.clickChip(
                                          service,
                                          _vm.filter.servicesSelect
                                        )
                                      },
                                    },
                                    model: {
                                      value: service.isOpen,
                                      callback: function ($$v) {
                                        _vm.$set(service, "isOpen", $$v)
                                      },
                                      expression: "service.isOpen",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.filterName(service, _vm.services)
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._l(_vm.filter.actionsSelect, function (action) {
                              return _c(
                                "v-chip",
                                {
                                  key: action,
                                  staticClass: "ma-1",
                                  attrs: { close: "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onClose(action)
                                    },
                                    "click:close": function ($event) {
                                      return _vm.clickChip(
                                        action,
                                        _vm.filter.actionsSelect
                                      )
                                    },
                                  },
                                  model: {
                                    value: action.isOpen,
                                    callback: function ($$v) {
                                      _vm.$set(action, "isOpen", $$v)
                                    },
                                    expression: "action.isOpen",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.filterName(action, _vm.actions)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: { color: "background" },
                          on: { click: _vm.limpar },
                          scopedSlots: _vm._u([
                            {
                              key: "loader",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "custom-loader" },
                                    [
                                      _c("v-icon", { attrs: { light: "" } }, [
                                        _vm._v("mdi-cached"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [_vm._v(" Limpar Filtro ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2 white--text",
                          attrs: {
                            loading: _vm.loading3,
                            color: "success",
                            disabled: _vm.disabledInputs,
                          },
                          on: { click: _vm.exportar },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", dark: "" } }, [
                            _vm._v(" mdi-download "),
                          ]),
                          _vm._v(" Exportar "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }