<template>
  <v-main>
    <v-row>
      <v-col cols="12" sm="4">
        <h2 class="mt-2 mb-8 titleColor--text">Auditoria</h2>
      </v-col>
    </v-row>
    <p>Exportar histórico de atividades em um período de até 60 dias.</p>
    <v-card>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12" sm="1">
            <v-card-title style="font-size: 1.2rem">Filtro</v-card-title>
          </v-col>
          <v-col cols="12" sm="3" class="pr-1" style="max-width: 273px">
            <v-dialog model="dialog" width="369px">
              <v-date-picker v-model="filter.dates" range @change="fillFilters()"></v-date-picker>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="computeRange"
                  outlined
                  label="Periodo *"
                  append-icon="mdi-calendar"
                  readonly
                  dense
                  :color="filterDateColor"
                ></v-text-field>
              </template>
            </v-dialog>
            <div id="date-hint-div">
              <p class="v-application">{{ pickerMessage }}</p>
            </div>
          </v-col>
          <v-col cols="12" sm="2" style="min-width: 240px">
            <v-autocomplete
              v-model="filter.usersSelect"
              multiple
              :items="users"
              append-icon="mdi-chevron-down"
              :item-text="(user) => `${user.username}`"
              label="Nome do usuário"
              placeholder="Todos"
              dense
              outlined
              :disabled="disabledInputs"
              rounded
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
            >
              <template v-slot:selection="{ index }" style="max-height;: 48px">
                <span v-if="index === 0">
                  {{ `${filter.usersSelect.length} selecionado${filter.usersSelect.length > 1 ? 's' : ''}` }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filter.ipsSelect"
              multiple
              :items="ips"
              append-icon="mdi-chevron-down"
              :item-text="(ip) => `${ip}`"
              label="IP do usuário"
              placeholder="Todos"
              dense
              outlined
              :disabled="disabledInputs"
              rounded
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
            >
              <template v-slot:selection="{ index }" style="max-height;: 48px">
                <span v-if="index === 0">
                  {{ `${filter.ipsSelect.length} selecionado${filter.ipsSelect.length > 1 ? 's' : ''}` }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filter.servicesSelect"
              multiple
              append-icon="mdi-chevron-down"
              :items="services"
              :item-text="(service) => `${service.description}`"
              item-value="name"
              label="Localização no sistema"
              placeholder="Todos"
              dense
              outlined
              :disabled="disabledInputs"
              rounded
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
            >
              <template v-slot:selection="{ index }" style="max-height;: 48px">
                <span v-if="index === 0">
                  {{ `${filter.servicesSelect.length} selecionado${filter.servicesSelect.length > 1 ? 's' : ''}` }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filter.actionsSelect"
              multiple
              append-icon="mdi-chevron-down"
              :items="actions"
              :item-text="(action) => `${action.description}`"
              item-value="name"
              label="Atividades"
              placeholder="Todos"
              dense
              outlined
              :disabled="disabledInputs"
              rounded
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
            >
              <template v-slot:selection="{ index }" style="max-height;: 48px">
                <span v-if="index === 0">
                  {{ `${filter.actionsSelect.length} selecionado${filter.actionsSelect.length > 1 ? 's' : ''}` }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <template>
              <div class="text-center">
                <v-chip
                  v-for="user in filter.usersSelect"
                  class="ma-1"
                  :key="user.id"
                  close
                  v-model="user.isOpen"
                  @input="onClose(user)"
                  @click:close="clickChip(user, filter.usersSelect)"
                >
                  {{ user }}
                </v-chip>
                <v-chip
                  v-for="ip in filter.ipsSelect"
                  class="ma-1"
                  :key="ip"
                  v-model="ip.isOpen"
                  @input="onClose(ip)"
                  @click:close="clickChip(ip, filter.ipsSelect)"
                  close
                >
                  {{ ip }}
                </v-chip>
                <v-chip
                  v-for="service in filter.servicesSelect"
                  class="ma-1"
                  :key="service"
                  v-model="service.isOpen"
                  @input="onClose(service)"
                  @click:close="clickChip(service, filter.servicesSelect)"
                  close
                >
                  {{ filterName(service, services) }}
                </v-chip>
                <v-chip
                  v-for="action in filter.actionsSelect"
                  class="ma-1"
                  :key="action"
                  v-model="action.isOpen"
                  @input="onClose(action)"
                  @click:close="clickChip(action, filter.actionsSelect)"
                  close
                >
                  {{ filterName(action, actions) }}
                </v-chip>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" align="right">
            <v-btn class="ma-2" color="background" @click="limpar">
              Limpar Filtro
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn
              :loading="loading3"
              color="success"
              class="ma-2 white--text"
              @click="exportar"
              :disabled="disabledInputs"
            >
              <v-icon left dark> mdi-download </v-icon>
              Exportar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import AdminService from '@/services/adminServices.js';
import { eventBus } from '../../../main.js';

export default {
  name: 'Auditoria',

  data: () => ({
    users: [],
    ips: [],
    services: [],
    actions: [],
    pickerMessage: 'Preencha um período de até 60 dias',

    filter: {
      dates: [],
      datesFormat: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
      ],
      usersSelect: [],
      ipsSelect: [],
      servicesSelect: [],
      actionsSelect: [],
    },

    loading3: false,
    disabledInputs: true,
    filterDateColor: 'primary',

    filterExport: {
      userName: '',
      ipAddress: '',
      service: '',
      action: '',
      fromTime: '',
      toTime: '',
    },
    toast: {
      message: '',
      type: '',
      show: false,
      topToast: 0,
    },
  }),
  computed: {
    computeRange: {
      get() {
        if (this.filter.dates == undefined || this.filter.dates.length == 0) {
          return 'Preencha um período *';
        }
        let from = this.filter.dates[0];
        let to = this.filter.dates[1];
        let fromMilis = this.getMillisecondsDate(from);
        let toMilis = this.getMillisecondsDate(to);

        if (this.isInvalidDates(fromMilis, toMilis)) {
          if (this.isRevertedDates(fromMilis, toMilis)) {
            this.pickerMessage = 'Preencha primeiro a data mais antiga';
          }
          document.querySelector('#date-hint-div').classList.add('v-messages__message');
          this.disabledInputs = true;
          this.filterDateColor = '#962d33';
        } else {
          this.pickerMessage = 'Preencha um período de até 60 dias';
          document.querySelector('#date-hint-div').classList.remove('v-messages__message');
          this.disabledInputs = false;
          this.filterDateColor = 'primary';
        }
        this.filter.datesFormat = [this.formatDate(from), this.formatDate(to)];
        return this.filter.datesFormat.join(' a ');
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    async fillFilters() {
      try {
        let from = this.getMillisecondsDate(`${this.filter.dates[0]} 00:00`);
        let to = this.getMillisecondsDate(`${this.filter.dates[1]} 23:59`);
        if (!this.isInvalidDates(from, to)) {
          let response = await AdminService.usability.findParams(from, to);
          let filters = response.data;
          this.users = filters.users;
          this.ips = filters.ips;
          this.services = filters.services;
          this.actions = filters.actions;
        }
      } catch (e) {
        console.info('Erro ao carregar os parametros do filtro', e);
      }
    },
    filterName(name, paramArr) {
      let result = '';
      paramArr.filter((p) => {
        if (p.name === name) {
          result = p.description;
        }
      });
      return result;
    },
    getMillisecondsDate(dateString) {
      var date = new Date(dateString);
      return date.getTime();
    },
    clickChip(user, paramArr) {
      paramArr.splice(user, 1);
    },
    isInvalidDates(from, to) {
      let sixtyDays = 60;
      return !from || !to || this.isRevertedDates(from, to) || (to - from) / 1000 / 60 / 60 / 24 > sixtyDays;
    },
    isRevertedDates(from, to) {
      return to < from;
    },
    async exportar() {
      this.loading3 = true;
      let filterRequest = {
        userNames: this.filter.usersSelect,
        ipAddresses: this.filter.ipsSelect,
        services: this.filter.servicesSelect,
        actions: this.filter.actionsSelect,
        fromTime: this.getMillisecondsDate(`${this.filter.dates[0]} 00:00`).toString(),
        toTime: this.getMillisecondsDate(`${this.filter.dates[1]} 23:59`).toString(),
      };
      let response = await AdminService.usability.downloadUsabilityReport(filterRequest);
      if (response) this.loading3 = false;
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `Relatório de ações no Agnes - De ${this.formatDate(this.filter.dates[0])} a ${this.formatDate(
        this.filter.dates[1],
      )} extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;
      link.click();
      this.showToast();
    },
    limpar() {
      this.filter = {};
      this.disabledInputs = true;
    },
    showToast() {
      this.toast.show = true;
      this.toast.type = 'success';
      this.toast.message = 'Download iniciado. Verifique o arquivo em seu computador.';
      this.toast.topToast = 78;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style>
#date-hint-div {
  font-size: 12px;
  margin-top: -18px;
}

#date-hint-div p {
  bottom: 3px;
}

#date-hint-div.v-messages__message {
  color: #962d33;
}
</style>
